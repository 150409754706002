




















































































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import {
  addDays,
  endOfDay, format, getUnixTime, parse, startOfDay,
} from 'date-fns';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import VueBaseNotify from '@/utils/widgets/VueBaseNotify';
import Meeting from '@/models/graphql/Meeting';
import { namespace, State } from 'vuex-class';
import DateTimeHelper from '@utils/helpers/DateTimeHelper';
import EpAgendaDashboardItemComponent
  from '@/components/exhibitor-portal/dashboard/agenda/EpAgendaDashboardItemComponent.vue';
import { zonedTimeToUtc } from 'date-fns-tz';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import PickerCalendarComponent from '@/components/calendar/PickerCalendarComponent.vue';
import PickerCalendarType from '@/utils/enums/PickerCalendarType';

const companyCalendarStore = namespace('CompanyCalendarStore');

type FilterMenuOptionType = Record<string, {
  list: Array<Meeting>;
  emptyMessage: string;
}>;
/* eslint-disable @typescript-eslint/camelcase,no-underscore-dangle */
@Component({
  components: {
    PickerCalendarComponent,
    FontAwesomeComponent,
    EpAgendaDashboardItemComponent,
    ButtonComponent,
    LoadingSpinnerComponent,
  },
})
export default class EpAgendaDashboardWidget extends mixins(
  VueRegisterStoreWidget,
  VueBaseNotify,
  VueBaseWidget,
) {
  @companyCalendarStore.Action
  private loadMeetings!: (filter: object) => Promise<Meeting[]>;

  @companyCalendarStore.State
  private meetings!: Meeting[];

  @State
  private dateLocale!: Locale;

  @State
  private selectedTzName!: string;

  private isLoading = true;

  private today = new Date();

  private selectedFilter = '0';

  private selectedCustomDateList: Meeting[] = [];

  private selectedCustomDateEmptyMessage = '';

  private showCalendar = false;

  private dateFilter = format(DateTimeHelper.getCurrentDateTime(), 'yyyy-MM-dd');

  private pickerCalendarType = PickerCalendarType;

  // eslint-disable-next-line class-methods-use-this
  private nextDay(n: number): Date {
    return addDays(zonedTimeToUtc(
      DateTimeHelper.getCurrentDateTime(),
      this.selectedTzName,
    ), n);
  }

  private filtersMenuOptions: FilterMenuOptionType = {
    0: {
      list: [],
      emptyMessage: `${this.$tc('ep-dashboard.agenda.incoming-empty-message', 0, { date: 'today' })}`,
    },
    1: {
      list: [],
      emptyMessage: `${this.$tc('ep-dashboard.agenda.incoming-empty-message', 0, { date: 'tomorrow' })}`,
    },
    2: {
      list: [],
      emptyMessage: `${this.$tc('ep-dashboard.agenda.incoming-empty-message', 0, {
        date: this.formatedDate(2),
      })}`,
    },
    3: {
      list: [],
      emptyMessage: `${this.$tc('ep-dashboard.agenda.incoming-empty-message', 0, {
        date: this.formatedDate(3),
      })}`,
    },
  };

  private get baseGqlFilter(): object {
    const { companyId } = this.$route.params;
    return {
      meetingRequest: {
        exhibitor: {
          uid: companyId,
        },
      },
    };
  }

  private get list(): Meeting[] {
    if (this.selectedFilter === 'custom') {
      return this.selectedCustomDateList;
    }
    return this.filtersMenuOptions[this.selectedFilter].list;
  }

  private get emptyMessage(): string {
    if (this.selectedFilter === 'custom') {
      return this.selectedCustomDateEmptyMessage;
    }
    return this.filtersMenuOptions[this.selectedFilter].emptyMessage;
  }

  created(): void {
    this.setDataConfig();
    this.onFilter(this.selectedFilter);
  }

  private onSelectedCustomDateFilter(filter: string[]): void {
    if (filter.length > 0) {
      this.isLoading = true;
      this.selectedFilter = 'custom';
      this.loadMeetings({
        startTimestamp_gte: getUnixTime(startOfDay(parse(filter[0], 'yyyy-MM-dd', new Date()))),
        startTimestamp_lte: getUnixTime(endOfDay(parse(filter[0], 'yyyy-MM-dd', new Date()))),
        ...this.baseGqlFilter,
      })
        .then((response: Meeting[]) => {
          this.selectedCustomDateList = response;
          this.selectedCustomDateEmptyMessage = `${this.$tc('ep-dashboard.agenda.incoming-empty-message', 0, {
            date: format(
              parse(filter[0], 'yyyy-MM-dd', new Date()),
              `${this.$t('app.date.monthDayShort')}`,
              { locale: this.dateLocale },
            ),
          })}`;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  private onFilter(filter: string): void {
    this.isLoading = true;
    this.selectedFilter = filter;
    this.loadMeetings({
      startTimestamp_gte: getUnixTime(startOfDay(this.nextDay(parseInt(this.selectedFilter, 10)))),
      startTimestamp_lte: getUnixTime(endOfDay(this.nextDay(parseInt(this.selectedFilter, 10)))),
      ...this.baseGqlFilter,
    })
      .then((response) => {
        this.filtersMenuOptions[this.selectedFilter].list = response;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private formatedDate(index: number): string {
    switch (index) {
      case 0:
        return 'Today';
      case 1:
        return 'Tomorrow';
      case 2:
      case 3:
        return format(
          this.nextDay(index),
          `${this.$t('app.date.monthDayShort')}`,
          { locale: this.dateLocale },
        );
      default:
        return '';
    }
  }

  private toggleCalendar(): void {
    this.showCalendar = !this.showCalendar;
  }

  private goToAgenda(): void {
    this.$router.push({
      name: 'exhibitor-portal-agenda',
    });
  }
}
